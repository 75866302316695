import React from 'react'
import Link from 'gatsby-link'
import Card from '../components/Card';
import Section from '../components/Section';
import Wave from '../components/Wave';
import staticdata from '../../staticdata.json'
import Cell from '../components/Cell';
import styled from 'styled-components'
import Navbar from '../components/Navbar/Navbar.js'
import '../components/contact.css'
import Footer from '../components/footer'
import ReactDOM from 'react-dom'
import App from '../components/App'
import Testimonial from '../components/Testimonial';
import Head from '../components/head'



const SectionCaption = styled.p`
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  color: #94A4BA;
  text-align: center;
`

const Button = styled.button`
    background: linear-gradient(102.24deg, #9B51E0 0%, #3436E7 100%);
    box-shadow: 0px 10px 20px rgba(101, 41, 255, 0.15);
    border-radius: 30px;
    color: white;
    border: none;
    padding: 16px 60px;
    
    
    font-weight: 600;
    font-size: 18px;
    justify-self: center;
    transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
    &:hover {
        box-shadow: 0 20px 40px rgba(0,0,0, 0.15);
        transform: translateY(-3px);
    }
`


const SectionCellGroup = styled.div`
  max-width: 800px;
  margin: 0 auto 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  padding: 0 20px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const ContactPage = () => (

  

  <div>
        <Head title="Contact Tima's Flavours" />

<Navbar />

    <div className="HeroContact">
      <div className="HeroContactGroup">
      <h1>Contact</h1>
      <p>Please use the form below to get in touch with us about your catering requirements. We en devour to respond as soon as possible to meet your catering needs.</p>
      
<div> 
  
  
  <div className="contact1">
    <div className="container-contact1">
<form className="contact1-form" method="post" action="https://getform.io/f/039eaa18-3d80-4747-9123-0c10418f9eb9">

<div class="wrap-input1">
<input className="input1" type="text" name="name" placeholder="Name" required /><span className="shadow-input1"></span>
</div>

<div class="wrap-input1">
<input className="input1" type="email" name="email" placeholder="Email" required /><span className="shadow-input1"></span>
</div>

<div class="wrap-input1">
<input type="tel" className="input1" name="tel" placeholder="Phone Number (optional)" /><span className="shadow-input1"></span>
</div>

<div class="wrap-input1">
<input className="input1" type="text" name="Date" placeholder="Date of the Event" required /><span className="shadow-input1"></span>
</div>

<div class="wrap-input1">
<input className="input1" type="text" name="Number of persons" placeholder="Number of Persons" required /><span className="shadow-input1"></span>
</div>

<div class="wrap-input1">
<input className="input1" type="text" name="Location" placeholder="Location of event" required /><span className="shadow-input1"></span>
</div>

<div class="wrap-input1">
<input className="input1" type="text" name="Diet" placeholder="Dietary Requirements" required /><span className="shadow-input1"></span>
</div>

<div class="wrap-input1">
<textarea className="input1"  name="message" placeholder="Additional Comments (e.g. Type of Event)" required /><span className="shadow-input1"></span>
</div>

<div class="container-contact1-form-btn"><button className="contact1-form-btn" type="submit"><span>Send Message</span></button></div>

</form>

</div>

</div><Wave />  </div>
 
      </div>
   
      
    </div>
 
   
    
    <div className="Cards">
      <h4>CUSTOMER STORIES</h4>
      <h2>5 STARS ON GOOGLE</h2></div>

<div className="Testimonial">
      
     
<Testimonial 
          title="We Hired Tima for our corporate event and the food was absolutely amazing as was the service! Highly recommended!"
          text=""
          author="Joshua"
          image='/images/josh.jpg' /> 
         
        <Testimonial 
          title="I absolutely recommend Tima to anyone who has an event that requires catering. I send my children to her cooking classes too!"
          text=""
          author="Elizabeth"
          image='/images/eli.jpg' />
             
    </div>
    <div className="Cardb">
    <Button>More Stories</Button>
</div>
   

     <Footer />
     

  </div>
)

export default ContactPage
